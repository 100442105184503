

/*
 * Check for browser support
 */

function checkSynth(){
    if ('speechSynthesis' in window) {
        console.log('Your browser <strong>supports</strong> speech synthesis.');
    } else {
        console.log('Sorry your browser <strong>does not support</strong> speech synthesis.<br>Try this in <a href="https://www.google.co.uk/intl/en/chrome/browser/canary.html">Chrome Canary</a>.');
    }    
}


// Fetch the list of voices and populate the voice options.
function loadVoices() {
  // Fetch the available voices.
	window.voices = speechSynthesis.getVoices();
    //console.log(window.voices);
  
}

function getVoice(name_list, lang) {
    //console.log(name_list);
    //console.log(lang);
    // Loop through each of the voices.
    let to_return=undefined;
    window.voices.forEach(function(voice, i) {
        if (to_return) {

        } else {
            if (name_list.length>0){
                if (lang) {
                    if(name_list.indexOf(voice.name)>-1 && lang.toLowerCase()==voice.lang.toLowerCase()) {to_return= voice}
                } else {
                    if(name_list.indexOf(voice.name)>-1) {to_return= voice}
                }
            } else {
                if(lang.lower()==voice.lang.toLowerCase()) {to_return= voice}
            }
        }        
	}); 

    //alert(to_return.name);
    console.log("Selected voice: ", to_return);
    return to_return;   
    //Mónica::es-ES
}


function init(){
    // Execute loadVoices.
    loadVoices();

    // Chrome loads voices asynchronously.
    window.speechSynthesis.onvoiceschanged = function(e) {
        loadVoices();
    };
}


// Create a new utterance for the specified text and add it to
// the queue.
function speak(text, callback) {

    if ('speechSynthesis' in window) {} else {return;}

    //console.log("Trying to speak:" + text);
    //console.log(callback);
    
    // Create a new instance of SpeechSynthesisUtterance.
    var msg = new SpeechSynthesisUtterance();
    
    // Set the text.
    msg.text = text;
    
    // Set the attributes.
    msg.volume = 1.0;
    msg.rate = 1.0;
    msg.pitch = 1.0;

    msg.addEventListener('end', (evt) => {
        if(callback) { callback();}
    })
    
    // Get first spanish voice
      msg.voice = getVoice(["Mónica"], "es-ES");

      //console.log("Speaking now...");
    
    // Queue this utterance.
    window.speechSynthesis.speak(msg);
}


export default {
    "init": init,
    "speak": speak,
    "loadVoices": loadVoices,
    "getVoice": getVoice,
    "checkSynth": checkSynth
}
    
